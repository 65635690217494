import * as client_hooks from '../../../src/hooks/index.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138')
];

export const server_loads = [0,2,4,9,18,19,12,20,14,21,15,16,7,8];

export const dictionary = {
		"/(nav)": [~22,[2],[3]],
		"/(nav)/@[slug]": [~23,[2,4],[3]],
		"/(nav)/@[slug]/about": [~24,[2,4],[3]],
		"/(nav)/@[slug]/members": [~25,[2,4],[3]],
		"/@[slug]/sessions": [~121],
		"/@[slug]/sessions/[slug]": [~122],
		"/@[slug]/tip": [~123],
		"/(nav)/account": [~41,[2,9],[3]],
		"/(nav)/account/chat": [~42,[2,9],[3]],
		"/(nav)/account/chat/[id]": [~43,[2,9],[3]],
		"/(nav)/account/event/[id]": [~44,[2,9],[3]],
		"/(nav)/account/event/[id]/chat": [~45,[2,9],[3]],
		"/(nav)/account/event/[id]/live": [~46],
		"/(nav)/account/following": [~47,[2,9],[3]],
		"/(nav)/account/my-stuff": [48,[2,9],[3]],
		"/(nav)/account/onboarding": [~49,[2,9],[3]],
		"/(nav)/account/sessions": [~52,[2,9],[3]],
		"/(nav)/account/sessions/create": [~53,[2,9],[3]],
		"/(nav)/account/session/[coachingId]": [~50,[2,9],[3]],
		"/(nav)/account/session/[coachingId]/[coachingSessionId]": [~51,[2,9],[3]],
		"/(nav)/account/settings": [~54,[2,9,10],[3]],
		"/(nav)/account/settings/about": [~55,[2,9,10],[3]],
		"/(nav)/account/settings/achievements": [~56,[2,9,10],[3]],
		"/(nav)/account/settings/availability": [~57,[2,9,10],[3]],
		"/(nav)/account/settings/communities": [~58,[2,9,10],[3]],
		"/(nav)/account/settings/connected-accounts": [~59,[2,9,10],[3]],
		"/(nav)/account/settings/discounts": [~60,[2,9,10],[3]],
		"/(nav)/account/settings/faq": [~61,[2,9,10],[3]],
		"/(nav)/account/settings/notifications": [~62,[2,9,10],[3]],
		"/(nav)/account/settings/payments": [~63,[2,9,10],[3]],
		"/(nav)/account/settings/pro": [~64,[2,9,10],[3]],
		"/(nav)/account/settings/security": [~65,[2,9,10],[3]],
		"/(nav)/account/settings/sessions": [~66,[2,9,10],[3]],
		"/(nav)/account/settings/short-links": [~67,[2,9,10],[3]],
		"/(nav)/account/settings/subscriptions": [~68,[2,9,10],[3]],
		"/(nav)/account/settings/testimonials": [~69,[2,9,10],[3]],
		"/(nav)/account/studio": [70,[2,9,11],[3]],
		"/(nav)/account/studio/availability": [~71,[2,9,11],[3]],
		"/(nav)/account/studio/communities": [~72,[2,9,11],[3]],
		"/(nav)/account/studio/communities/[id]": [~73,[2,9,11],[3]],
		"/(nav)/account/studio/contests": [~74,[2,9,11],[3]],
		"/(nav)/account/studio/customers": [~75,[2,9,11],[3]],
		"/(nav)/account/studio/customers/[id]": [~76,[2],[3]],
		"/(nav)/account/studio/discounts": [~77,[2,9,11],[3]],
		"/(nav)/account/studio/events": [~78,[2,9,11],[3]],
		"/(nav)/account/studio/events/[id]": [~79,[2,9,11],[3]],
		"/(nav)/account/studio/gift-cards": [~80,[2,9,11],[3]],
		"/(nav)/account/studio/guides": [~81,[2,9,11],[3]],
		"/(nav)/account/studio/overview": [~82,[2,9,11],[3]],
		"/(nav)/account/studio/referrals": [~83,[2,9,11],[3]],
		"/(nav)/account/studio/sessions": [~84,[2,9,11],[3]],
		"/(nav)/account/studio/testimonials": [~85,[2,9,11],[3]],
		"/(nav)/account/studio/videos": [86,[2,9,11],[3]],
		"/(nav)/account/wallet": [~87,[2,9],[3]],
		"/auth/account/create": [124,[17]],
		"/auth/account/forgot-password": [125,[17]],
		"/auth/account/login": [126,[17]],
		"/auth/account/reset-password": [~127,[17]],
		"/auth/account/verify-email": [~128,[17]],
		"/auth/external/[...provider]": [129,[18]],
		"/checkout": [~130,[19]],
		"/checkout/reschedule": [~131,[19]],
		"/(nav)/communities": [~88,[2],[3]],
		"/(nav)/communities/create": [~99,[2],[3]],
		"/(nav)/communities/mine": [~100,[2],[3]],
		"/(nav)/communities/[filter=groupsFilter]": [~89,[2],[3]],
		"/(nav)/communities/[slug]": [~90,[2],[3]],
		"/(nav)/communities/[slug]/about": [~91,[2],[3]],
		"/(nav)/communities/[slug]/members": [~92,[2],[3]],
		"/(nav)/communities/[slug]/settings": [93,[2,12],[3]],
		"/(nav)/communities/[slug]/settings/details": [94,[2,12],[3]],
		"/(nav)/communities/[slug]/settings/discord-roles": [95,[2,12],[3]],
		"/(nav)/communities/[slug]/settings/tiers": [96,[2,12],[3]],
		"/(nav)/communities/[slug]/settings/topics": [97,[2,12],[3]],
		"/(nav)/communities/[slug]/tiers": [98,[2],[3]],
		"/(nav)/events": [~101,[2,13],[3]],
		"/(nav)/events/mine": [~104,[2,13],[3]],
		"/(nav)/events/replays": [~105,[2,13],[3]],
		"/(nav)/events/replays/mine": [~106,[2,13],[3]],
		"/(nav)/events/[filter=eventsFilter]": [~102,[2,13],[3]],
		"/(nav)/events/[slug]": [~103,[2,13],[3]],
		"/feedback/[...board]": [132,[20]],
		"/gamerzclass": [~133],
		"/gift-cards": [~134],
		"/(nav)/guides": [~107,[2,14],[3]],
		"/(nav)/guides/create": [~109,[2,14],[3]],
		"/guides/create/[guideSlug]": [~135,[21]],
		"/guides/create/[guideSlug]/[chapterSlug]": [~136,[21]],
		"/(nav)/guides/mine": [~110,[2,14],[3]],
		"/guides/preview/[guideSlug]/[chapterSlug]": [~137,[21]],
		"/(nav)/guides/view/[guideSlug]": [~111,[2,14],[3]],
		"/(nav)/guides/view/[guideSlug]/[chapterSlug]": [~112,[2,14],[3]],
		"/(nav)/guides/[type=guidesFilter]": [~108,[2,14],[3]],
		"/(nav)/posts/[id]": [~113,[2],[3]],
		"/pro": [138],
		"/(nav)/rules": [~114,[2],[3]],
		"/(nav)/sessions": [~115,[2,15],[3]],
		"/(nav)/sessions/mine": [~117,[2,15],[3]],
		"/(nav)/sessions/[filter]": [~116,[2,15],[3]],
		"/(nav)/tags/[tag]/[...path]": [~118,[2,16],[3]],
		"/(nav)/the-first-annual-metafy-monthly-contest": [~119,[2],[3]],
		"/(nav)/updates": [~120,[2],[3]],
		"/(nav)/[game]": [~26,[2,5],[3]],
		"/(nav)/[game]/communities": [~27,[2,5],[3]],
		"/(nav)/[game]/communities/mine": [~29,[2,5],[3]],
		"/(nav)/[game]/communities/[filter=groupsFilter]": [~28,[2,5],[3]],
		"/(nav)/[game]/events": [~30,[2,5,6],[3]],
		"/(nav)/[game]/events/mine": [~32,[2,5,6],[3]],
		"/(nav)/[game]/events/replays": [~33,[2,5,6],[3]],
		"/(nav)/[game]/events/replays/mine": [~34,[2,5,6],[3]],
		"/(nav)/[game]/events/[filter=eventsFilter]": [~31,[2,5,6],[3]],
		"/(nav)/[game]/guides": [~35,[2,5,7],[3]],
		"/(nav)/[game]/guides/mine": [~37,[2,5,7],[3]],
		"/(nav)/[game]/guides/[type]": [~36,[2,5,7],[3]],
		"/(nav)/[game]/sessions": [~38,[2,5,8],[3]],
		"/(nav)/[game]/sessions/mine": [~40,[2,5,8],[3]],
		"/(nav)/[game]/sessions/[filter]": [~39,[2,5,8],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';